/* Estilos para o componente DatePicker */
.custom-datepicker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f0f0;
    padding: 10px;
    font-weight: bold;
    user-select: none;
}

/* Estilize os botões de navegação */
.custom-datepicker-header button {
    background-color: #007bff;
    border: none;
    border-radius: 50%;
    color: white;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
}

/* Estilize o mês e ano */
.custom-datepicker-header span {
    text-transform: capitalize;
}


.drop-container {
    border: 2px dashed #cccccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .draggingx {
    background-color: #f0f0f0;
  }
  

  .custom-button {
    height: 50px; 
    font-weight: bold; 
    
}

.close-icon {
    margin-left: 5px; /* Ajuste o valor conforme necessário para adicionar espaço à esquerda */
    color: red; /* Define a cor para vermelho */
    cursor: pointer; /* Adiciona cursor indicando que é clicável */
  }
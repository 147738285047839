.centralizadortela{
    margin-top: -20rem;
    position: absolute;
    font-size: 50px;
    font-weight: bolder;
    margin-left: 5%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
    
}

.cardpersonalizado{
    border-radius: 1px;
    border: 1px solid gray;
    
}
.botaoPadrao{
    border-radius: 60%;
    width: 80px;
    height: 80px;

}
body{
    background-color: white;
}

.timer {
    font-size: 2em;
    font-family: monospace;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timer span {
    display: inline-block;
    width: 1em;
    text-align: center;
    transition: transform 0.5s ease-in-out;
}

.timer span.hide {
    transform: translateY(-100%);
}

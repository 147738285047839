/* Estilo para o círculo vermelho */
.circle-red {
    display: inline-block;
  }
  
  .not-finished {
    position: relative;
    background-color: rgba(160, 7, 7, 0.767);
    color: white; /* Ajuste a cor do texto conforme necessário */
    padding: 4px 8px; /* Ajuste o espaçamento interno conforme necessário */
    border-radius: 10%;
    font-weight: bold;
  }
  

  /* Estilo para quebrar linha em telas menores que 1054x870 */
@media (max-width: 1054px) and (max-height: 870px) {
  .projeto-label {
      display: block;
  }
}

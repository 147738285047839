.scrollBox {
    height: 100px;
    overflow: auto;
    white-space: nowrap;

}
.scrollNfeProd{
     height: 65vh;
    overflow: auto;
    white-space: nowrap;
}
.centralizadomeio{
    padding-left: 30% !important;
}
.tabelaNfe {
    height: 25vh;
    overflow: auto;
    white-space: nowrap;

}
.cardmov p strong {
    font-weight: bold;
    font-size: 16px;
  }
  
  .custom-label p strong {
    font-weight: bold;
    font-size: 16px;
  }
  
  .custom-label-value {
    font-size: 16px;
  }

.filtros_box {
    height: 380px;
    

}

.scrollBoxCard {
    height: 200px;
    width: 100%;
    padding-left: 14px;
    overflow: auto;
    white-space: nowrap;

}

.scrollBox2 {
    height: 400px;
    overflow: auto;
    white-space: nowrap;

}

.scrollBoxNfe {
    overflow: auto;
    white-space: nowrap;

}

.table-border2 {
    height: 54vh;
    width: auto;
    overflow: auto;
}

.setWidth {
    max-width: 100px;
}

.concat div {
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: inherit;
}

.cardmoperso01 {
    padding-right: 1%;
    height: 100px;
    width: 100%;
}

.dividerCustom {
    position: absolute;
    left: 60.5%;
    top: 30%;
    bottom: 15%;
    border-left: 2px solid rgb(176, 176, 176);
    border-radius: 125px/12px;
    box-shadow: 0 0 8px black;
    overflow: hidden;
}

.espacoentreinput {
    margin: 0 13px;
}

.fundocardform {
    background-color: #f9f9f9;
}

.colorfundocard {
    background-color: #fffff0;
}

.colorfundocard2 {
    background-color: #ffff;
}


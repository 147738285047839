/* home.css */

.centralizarx {
    padding-left: 2rem;
}

.custom-image {
    height: auto;
    max-height: 70vh;
}

.carousel-control-prev {
    margin-top: 33%;
}

.carousel-control-next {
    margin-top: 33%;
}

.carousel-control-prev,
.carousel-control-next {
    z-index: 1; /* Ajuste conforme necessário */
}

.carousel-controls-container {
    background-color: rgba(15, 1, 1, 0.5);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: transparent; /* Certifique-se de que o ícone tenha fundo transparente */
}

.carousel-control-next-icon{ 
    background-image: url("https://cdn-icons-png.flaticon.com/512/189/189253.png");
    height: 50px;
    width: 50px;
}
 .carousel-control-prev-icon{ 
    
        background-image: url("https://cdn-icons-png.flaticon.com/512/189/189253.png");
        height: 50px;
        width: 50px;
        transform: rotate(180deg); /* Rotaciona a imagem em 90 graus */
   
    
}
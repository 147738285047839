.donut-chart-container {
  width: 40%;
  margin: 0 auto;
  height: 40%;
}

.victory-pie-label {
  fill: white;
  font-size: 14%;
}

.flex-container {
  display: flex;
}
.mover {
  margin-left: -3%;
}

.box {
  border: 1% solid #000;
}

.centralizado {
  margin-left: 35%;
  font-size: 1.5rem;
  font-weight: bold;
}

.barra-div {
  width: auto;
}

.export-button {
  float: right;
}
.image-container {
  display: inline-block;
}

.filter{
  margin-left: 40%;
  font-size: 1.5rem;
 
}
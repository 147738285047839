/* To center the spinner*/
.pos-center {
    position: fixed;
    top: calc(50% - 50px);
    
    left: calc(55% - 100px);
  }
  
  .loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #3498db;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
   
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

  .loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Define o fundo escuro com transparência */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-content {
 
    padding: 20px;
  }

  .content-container.loading {
    opacity: 0.5; /* Diminui a opacidade do conteúdo quando a tela de loading está sendo exibida */
    pointer-events: none; /* Desabilita interações com o conteúdo */
  }
  
  .footer.hidden {
    display: none; /* Oculta o footer */
  }
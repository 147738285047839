/* Importe a fonte do FontAwesome em seu arquivo de estilos CSS, se ainda não o fez */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

.arrow-icon {
  /* Defina o tamanho e a cor da imagem da seta */
  font-size: 20px;
  color: #000; /* Altere a cor conforme necessário */
  /* Defina a posição do background como centralizado e centralizado à direita */
  background-position: center right;
  /* Defina o espaço à direita para a imagem da seta para evitar sobreposição de texto */
  margin-left: 90%;
  /* Defina o cursor do mouse como ponteiro para indicar que é clicável */
  cursor: pointer;

}

.arrow-container {
  display: flex;
  justify-content: center; /* centraliza horizontalmente */
  align-items: center; /* centraliza verticalmente */
  height: 100%; /* define a altura do container */
}

.tabelapersonalizada {
  border-collapse: collapse;
  width: 100%;
}

.tabelapersonalizada th,
.tabelapersonalizada td {
  padding: 8px;
  text-align: left;
}

.tabelapersonalizada th {
  background-color: #e8e8e8;
}

.tabelapersonalizada tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tabelapersonalizada tr:hover {
  background-color: #ddd;
}

.tabelapersonalizada td {
  border-bottom: 1px solid #ddd;
}
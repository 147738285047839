.btnbutton{
    background-color: red;
    position: absolute;
    left: 96%;
    
}
.centralizar{
    position: relative;
    left: 40%;
}

.quebrarlinha{
    display: block;
    margin-bottom: 100px;
    font-size:10px;
    line-height: 10px;
}
.quebrarlinha2{
    display: block;
    margin-bottom: 2px;
    font-size:10px;
    line-height: 10px;
}
@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: Raleway, sans-serif;
}

.body {
	background-image: url("https://static.vecteezy.com/ti/fotos-gratis/p2/2978358-close-up-de-pessoas-trabalhando-no-escritorio-gratis-foto.jpg");
	background-repeat: no-repeat;
	background-size: 100%;
	bottom: 0;
	color: black;
	left: 0;
	overflow: auto;
	padding: 0em;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	background-size: cover;


}

.invalid::placeholder {
	color: red;
}

.container {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
}

.screen {
	background: linear-gradient(90deg, #6F1C21, #6F1C21);
	position: relative;
	height: 550px;
	width: 360px;
	box-shadow: 0px 0px 24px #6F1C21;
}

.screen__content {
	z-index: 1;
	position: relative;
	height: 100%;
}

.screen__background {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);
}

.screen__background__shape {
	transform: rotate(45deg);
	position: absolute;
}

.screen__background__shape1 {
	height: 580px;
	width: 420px;
	background: #FFF;
	top: -50px;
	right: 120px;
	border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
	height: 220px;
	width: 220px;
	background: #6F1C21;
	top: -172px;
	right: 0;
	border-radius: 32px;
}

.screen__background__shape3 {
	height: 540px;
	width: 190px;
	background: linear-gradient(270deg, #6F1C21, #6F1C21);
	top: -24px;
	right: 0;
	border-radius: 32px;
}

.screen__background__shape4 {
	height: 400px;
	width: 200px;
	background: #6F1C21;
	top: 420px;
	right: 50px;
	border-radius: 60px;
}

.login {
	width: 310px;
	padding: 30px;
	padding-top: 55px;
	
}

.login__field {
	padding: 20px 0px;
	position: relative;
}

.login__icon {
	position: absolute;
	
	top: 40px;
	color: #812128;
}

.login__icon2 {
	color: #812128;
}

.login__input {
	border: none;
	border-bottom: 2px solid #812128;
	background: none;
	padding: 10px;
	padding-left: 24px;
	font-weight: 700;
	width: 75%;
	transition: .2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
	outline: none;
	
	border-bottom-color: #812128;
}

.login__submit {
	background: #fff;
	font-size: 14px;
	margin-top: 40px;
	margin-left: 20px;
	padding: 16px 20px;
	border-radius: 26px;
	border: 1px solid #812128;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 100%;
	color: #9b3404;
	box-shadow: 0px 2px 2px #9b3404;
	cursor: pointer;
	transition: .2s;
}
.login__show-password-button {
	background: #6F1C21;
	border: none;
	color: #ffffff;
	margin-right: -30px;
  }

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: #6F1C21;
	outline: none;
}

.button__icon {
	font-size: 24px;
	margin-left: auto;
	color: #812128;
}

.social-login {
	position: absolute;
	height: 140px;
	width: 300px;
	text-align: center;
	bottom: 0;
	right: -40px;
	color: #fff;
}

.social-icons {
	display: flex;
	align-items: center;
	justify-content: center;
}

.social-login__icon {
	padding: 20px 10px;
	color: #fff;
	text-decoration: none;
	text-shadow: 0px 0px 8px #812128;
}

.social-login__icon:hover {
	transform: scale(1.5);
}

.personbody {
	background-color: silver;
	background-repeat: no-repeat;
	bottom: 0;
	color: black;
	left: 0;
	overflow: auto;
	
	padding: 3em;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.footerperson {
	position: fixed;
	font-size: 12px;
	font-family: "Roboto Condensed", sans-serif;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.5rem;
	background-color: #6F1C21;
	color: #FFF;
	text-align: right;
}

.fontemaiscula {
	text-transform: uppercase;
}

.fontcustom1 {
	font-size: 12px;
}

.personalizadologo{
	
	margin-right: 11.2rem;
	margin-top: 5rem;
}
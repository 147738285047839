.scrollBox {
    height: 100px;
    overflow: auto;
    white-space: nowrap;

}

.table-border2{
    height: 54vh;
    width: auto;
    overflow: auto;
}
.setWidth{
    max-width: 100px;
} 
.concat div{
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: inherit;
}

.cardmoperso01{
    padding-right: 1%;
    height: 100px;
    width: 100%;
 }

 .dividerCustom{
    position: absolute;
    left:60.5%;
    top:30%;
    bottom:15%;
    border-left:2px solid rgb(176, 176, 176);
    border-radius: 125px/12px;
    box-shadow: 0 0 8px black;
    overflow:hidden;
}

.cantodireito{
    left: 0%;
}

.cantodireito2{
    left: 0%;
}

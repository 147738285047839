.imagemlogo{
    background-image: url("jcrlogo.png");
    background-color: #222d32;
}

.main-header{
    background-color:  #222d32;
}

.border{
    background-color:  #fff;
}
.skin-blue .main-header .logo:hover {
    background-color:  #222d32;
}

.skin-blue .main-header .logo {
    background-color:  #222d32;
}

a {
    color: #fff;
}

.skin-blue .sidebar a {
    color:  #fff;
}

.skin-blue .sidebar a {
    color:  #fff;
}

.skin-blue .sidebar a {
    color:  #fff;
}
.body{
    color: #222d32;
}
li {
    list-style-type: none;
  }
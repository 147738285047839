.h3default {
    color: #fff;
}


.corred {
    background-color: red;
    color: #fff;
}

.corblue {
    background-color: blue;
    color: #fff;
    background: blue;
}


.scrollBoxChamado {
    height: 380px;
    overflow: auto;
    white-space: nowrap;

}

.scrollBoxChamado {
    overflow: auto;
    white-space: nowrap;

}

.table-border2Chamado {
    height: 54vh;
    width: auto;
    overflow: auto;
}

.setWidth {
    max-width: 100px;
}

.concat div {
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: inherit;
}

.cardmoperso01 {
    padding-right: 1%;
    height: 100px;
    width: 100%;
}

.dividerCustom {
    position: absolute;
    left: 60.5%;
    top: 30%;
    bottom: 15%;
    border-left: 2px solid rgb(176, 176, 176);
    border-radius: 125px/12px;
    box-shadow: 0 0 8px black;
    overflow: hidden;
}

.espacoentreinput {
    margin: 0 13px;
}

.fundocardform {
    background-color: #f9f9f9;
}

.colorfundocard {
    background-color: #fffff0;
}

.colorfundocard2 {
    background-color: #ffff;
}


.scrollverticalmente {
    max-width: 100%;
    overflow: scroll;
   
}

.scrollBoxChat{
    height: 26vh;
    overflow: auto;
    white-space: nowrap;
}


.largurafixa{
    height: 45vh;
}

.error-message {
    font-size: 1rem;
    line-height: 1.2;
    word-break: break-word;
  }
  .alert .fa {
    margin-right: 0.5rem; /* Espaçamento entre o ícone e o texto */
  }
  
  .error-message {
    padding: 0.5rem; /* Espaçamento interno (padding) para o texto */
  }
.ic {
   /* margin-left: 1px; */ /* Remova essa linha */
}

.menu-item {
  cursor: pointer;
}

.menu-item{
  width: 10px;
}


@media (min-width: 768px) {
  .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li:hover>a>span:not(.pull-right),
  .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li:hover>.treeview-menu {
    display: block !important;
    position: absolute;
    width: 210px;
    left: 60px;
  }
}
.sidebar-menu, .main-sidebar .user-panel, .sidebar-menu>li.header {

  margin-left: 0.24rem !important;
}

.autorizadoUso {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: yellow;
  margin-left: 10%;
}

.cienteOperacao {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: green;
  margin-left: 10%;
}

.reijeitado {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  margin-left: 10%;
}

.quebradelinha {

  max-width: 100%;
}

#divaid {

  display: inline-block;
}

.modaltext {
  cursor: not-allowed;
  pointer-events: all !important;
}


@media (max-width: 767px) {
  .mobile-buttons {
    display: flex;
    flex-direction: column;
  }

  .mobile-buttons button {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .desktop-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .desktop-buttons button {
    margin-left: 10px;
  }

}

.desktop-buttons button:last-child {
  margin-left: 10px;
}


.cardperson {
  max-width: 80%; /* aumentar o valor da largura máxima do card */
  margin: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}


.cardpersonalizadobox {
  float: left;
  display: inline-block;
  right: 50%;
  margin-right: 12px;
}

.cardpersonalizadobox1 {
  display: inline-block;
  margin-right: 12px;
}

.cardpersonalizadobox2 {
  display: inline-block;
  margin-right: 12px;
}

.cardpersonalizadocd{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px; /* aumentar o valor do padding */
  margin-right: 10px;
  display: inline-block;
  font-size: 16px; /* aumentar o tamanho da fonte */
}
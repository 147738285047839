.not-finished {
    position: relative;
    background-color: rgba(160, 7, 7, 0.767);
    color: white;
    padding: 0.02em 0.1em; /* Adjust padding as needed */
    border-radius: 0.25em; /* Adjust border-radius as needed */
    font-weight: bold;
    display: flex; /* Use flexbox to center text */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
}
.not-finished-yellow {
    position: relative;
    background-color: rgba(244, 164, 5, 0.767);
    color: white;
    padding: 0.02em 0.1em; /* Adjust padding as needed */
    border-radius: 0.25em; /* Adjust border-radius as needed */
    font-weight: bold;
    display: flex; /* Use flexbox to center text */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
}

.not-finished-green {
    position: relative;
    background-color: rgba(0, 89, 9, 0.767);
    color: white;
    padding: 0.02em 0.1em; /* Adjust padding as needed */
    border-radius: 0.25em; /* Adjust border-radius as needed */
    font-weight: bold;
    display: flex; /* Use flexbox to center text */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
}

.not-finished-blue {
    position: relative;
    background-color: rgba(0, 89, 9, 0.767);
    color: white;
    padding: 0.02em 0.1em; /* Adjust padding as needed */
    border-radius: 0.25em; /* Adjust border-radius as needed */
    font-weight: bold;
    display: flex; /* Use flexbox to center text */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
}

.emAndamento {
    background-color: #F39C12;
    color: white;
    text-align: center;
    width: 10%; 
}

.naoIniciada {
    background-color:  rgba(160, 7, 7, 0.767);
    color: white;
    text-align: center;
    width: 10%; 
}
.finalizada {
    background-color: #00A65A;
    color: white;
    text-align: center;
    width: 10%; 
}
.entregue {
    background-color:  #00C0EF;
    color: white;
    text-align: center;
    width: 10%; 
}





.scroolvertical {
    max-width: 100%;
    overflow-y: auto;
    max-height: 300px; /* Defina a altura máxima desejada */
    border: 1px solid #ccc;
}

.table-container {
    max-height: 100%; /* Garante que a tabela não ultrapasse a altura máxima da área de rolagem */
    overflow: hidden; /* Oculta o conteúdo que ultrapassa a altura máxima da tabela */
}

/* Estilos adicionais da tabela, se necessário */


  


  
  .minha-tabela {
    width: 100%;
  }
  
  .minha-linha {
    white-space: nowrap;
  }


.botao {
    background: url("https://cdn.icon-icons.com/icons2/2699/PNG/512/microsoft_powerbi_logo_icon_169958.png"); 
    height:35px;
    width:90px;
    border:0;
    outline:0;
    -moz-outline:0;
    cursor:pointer;

}
.botao:hover {
   background: url("https://cdn.icon-icons.com/icons2/2699/PNG/512/microsoft_powerbi_logo_icon_169958.png");
    background-repeat: no-repeat;
    height:35px;
    width:90px;
}
.botao:active {
    background: url("https://cdn.icon-icons.com/icons2/2699/PNG/512/microsoft_powerbi_logo_icon_169958.png");
    background-repeat: no-repeat;
    height:35px;
    width:90px;
}
body {
    margin: 0;
    padding-bottom: 60px; /* Adicione um espaço em branco no final da página igual à altura do footer */
    background-color: #ecf0f5;
  }
  
  .main-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px; /* Ajuste a altura do footer conforme necessário */
    background-color: #fff; /* Defina a cor de fundo do footer */
    border-top: 1px solid #ddd; /* Adicione uma borda superior opcional */
  }
  
  .footer-content {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px; /* Ajuste a altura do footer conforme necessário */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .footer-content strong {
    text-align: center;
  }
  
  .footer-content a {
    color: #00f;
  }
  